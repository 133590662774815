<template>
  <div class="scientific">
    <div class="top-artice-title">
      <h1>{{ form.title }}</h1>
      <div class="flex">
        <span>时间：{{ form.creatDate|formatTime }}</span>
        <!-- <span>来源:{{form.source}}</span> -->
        <span>作者：{{ form.author }}</span>
        <span>点击数：{{ form.chickNum || 0 }}</span>
      </div>
    </div>
    <div class="artice-content" v-html="form.content"></div>

    <div v-if="form.appendixes&&form.appendixes.length>0" class="file-box">
      <div class="left">
        附件：
      </div>
      <div class="right">
        <el-link v-for="(item,index) in form.appendixes" :key="index" :href="item.picUrl" target="_blank">
          {{ item.description }}
        </el-link>
      </div>
    </div>
    <div class="flex updown-box">
      <el-link v-if="after.idAfter" :href="`/editor/1?id=${after.idAfter}&titleType=${after.typeAfter}`">
        上一篇：{{ after.titleAfter }}
      </el-link>
      <el-link v-if="before.idBefore" :href="`/editor/1?id=${before.idBefore}&titleType=${before.typeBefore}`">
        下一篇：{{ before.titleBefore }}
      </el-link>
    </div>

  </div>
</template>

<script>
import {queryDetails} from '@/api/index'

export default {
  data() {
    return {
      activeName: 'first',
      page: {
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      navTitle: "",
      form: {
        appendixes: []
      },
      after: {
        titleAfter: "",
        typeAfter: "",
        idAfter: ""
      },
      before: {
        idBefore: "",
        titleBefore: "",
        typeBefore: ""
      },
      type: this.$route.params.id,
      titleType: this.$route.query.titleType
    };
  },
  created() {
    this.getInfo()
  },
  watch: {
    $route(e) {
      console.log('设置', e.params.id)
    }
  },
  methods: {
    getInfo() {
      const data = {
        did: this.$route.query.id,
        documentType: this.$route.query.titleType
      }
      queryDetails(data).then(res => {
        this.form = {...res.data.data}
        this.after = {
          titleAfter: this.form.titleAfter,
          typeAfter: this.form.typeAfter,
          idAfter: this.form.idAfter
        }
        this.before = {
          idBefore: this.form.idBefore,
          titleBefore: this.form.titleBefore,
          typeBefore: this.form.typeBefore
        }
      })
    },

    handleClick() {
    },
    handleSizeChange() {
    },
    handleCurrentChange() {
    }
  },
  destroyed() {
    // this.$refs.vueRef.pause();//暂停
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.scientific {
  position: relative;
  margin-top: 40px;

  .el-link.el-link--default {
    color: #075c9f !important;
    padding: 0px;
    margin-bottom: 10px;
  }

  /deep/ .el-tabs__item {
    font-size: 16px;
  }

  .top-artice-title {
    text-align: center;
    margin-bottom: 20px;

    .flex {
      justify-content: center;

      span {
        padding: 0 10px;
      }
    }
  }

  .artice-content {
    margin: 20px 0 40px 0;
    min-height: 300px;
    font-size: 14px;

    /deep/ img {
      max-width: 100%;
    }

    /deep/ div[data-w-e-type="video"] {
      text-align: center;
    }
  }

  .artice-video {
    text-align: center;

    video {
      height: 300px;
      width: 800px;
      background: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .flex {
    display: flex;
    font-size: 14px;
  }

  .file-box {
    display: flex;
    font-size: 14px;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }
  }

  .updown-box {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0;

    span {
      padding: 10px 0;
    }
  }

  h1 {
    text-align: center;
  }

  .scientific-list {
    ul {
      list-style-type: circle;
    }

    li::before {
      content: "";
      width: 5px;
      height: 5px;
      border: 1px dotted #a1a1a1;
      /* Rotate */
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      /* Rotate Origin */
      -webkit-transform-origin: 0 100%;
      -moz-transform-origin: 0 100%;
      -ms-transform-origin: 0 100%;
      -o-transform-origin: 0 100%;
      transform-origin: 0 100%;
      position: absolute;
      left: 5px;
      top: 15px;
    }

    li {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 30px;
      padding-left: 40px;
      position: relative;

      /deep/ .el-link {
        font-size: 16px;
      }

      /deep/ .el-link.el-link--primary {
        color: #000;
      }
    }
  }

  .block {
    margin-top: 20px;
    text-align: center;
  }

  /deep/ .el-tabs__content {
    padding-bottom: 40px;
  }
}

.right-breadcrumb {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 10px;

  .desc {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 768px) {
  .scientific .flex {
    font-size: 12px !important;
  }
  .scientific h1 {
    font-size: 16px !important;
  }
  .scientific .file-box {
    padding: 0 20px;
  }
  .scientific .artice-content {
    width: 90% !important;
    margin: 0 auto !important;

    video {
      width: 100% !important;
    }
  }
  .scientific .updown-box {
    padding: 0 15px;
  }

}
</style>